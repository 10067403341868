<template>
  <v-row class="pt-4" align="center" justify="center" no-gutters>
    <template v-if="isLoading">
      <v-col
        v-for="index in Array.from(Array(3).keys())"
        :key="index"
        class="col-12 my-2 parties-preview__item px-5 py-3"
      >
        <v-skeleton-loader type="text"></v-skeleton-loader>
      </v-col>
    </template>

    <template v-else>
      <ContractCreatedPartyPreview
        v-for="(signIdentitiesLink, index) in signIdentitiesLinks"
        :sign-identity-link-data="signIdentitiesLink"
        :key="index"
      />
    </template>
  </v-row>
</template>

<script>
import { defineComponent } from 'vue'
import { internalApiRequest } from '@/shared/services/apiService'
import { useSignIdentitiesStore } from '@/features/contracts/stores/signIdentities'
import ContractCreatedPartyPreview from '@/features/contracts/components/ContractCreatedPartyPreview.vue'

export default defineComponent({
  name: 'ContractCreatedPartiesPreview',
  components: {
    ContractCreatedPartyPreview,
  },
  setup() {
    const signIdentitiesStore = useSignIdentitiesStore()

    return {
      signIdentitiesStore,
    }
  },
  data() {
    return {
      loaded: false,
      signIdentitiesLinks: [],
    }
  },
  computed: {
    isLoading() {
      return !this.loaded
    },
  },
  beforeMount() {
    this.signIdentitiesStore.resetSignIdentities()
  },
  mounted() {
    this.loaded = false

    this.signIdentitiesStore.fetchSignIdentities(this.$route.params?.contractId).finally(() => {
      internalApiRequest({
        method: 'GET',
        path: `api/v1/contracts/${this.$route.params?.contractId}/links`,
      })
        .then((resp) => {
          this.signIdentitiesLinks = resp.links
        })
        .finally(() => {
          this.loaded = true
        })
    })
  },
})
</script>

<style lang="scss" scoped>
.parties-preview__item {
  box-sizing: border-box;
  border: 1px solid #d4dff0;
  border-radius: 5px;
  background-color: #ffffff;
}
</style>
