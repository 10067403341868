<template>
  <v-container class="complete__wrapper mt-0 mt-md-5 pa-0">
    <v-container class="complete__wrapper-content px-5 px-lg-0 pt-5 pb-10 mx-auto">
      <v-row class="pt-5 pb-10 mx-auto text-center" align="center" justify="center" no-gutters>
        <v-col class="col-12">
          <v-img class="mx-auto" :src="headerImageFile" height="119.12px" width="156px" contain></v-img>
        </v-col>

        <v-col class="col-12 pt-3 pt-md-6">
          <h2 class="complete__title">{{ $t('general.congratulations') }}</h2>
        </v-col>

        <v-col class="col-12 pt-3 pt-md-4">
          <p class="complete__info mb-0">{{ $t('general.everything_went_well') }}</p>
        </v-col>

        <v-col class="col-12 pt-5 pt-md-10">
          <v-btn color="primary" @click="onRedirectToContractDetail">
            {{ $t('contract.show_contract') }}
          </v-btn>
        </v-col>
      </v-row>

      <v-row class="pt-3 pb-2 mx-auto" align="center" justify="center" no-gutters>
        <v-col class="col-12">
          <h3 class="complete__subtitle">{{ $t('contract.parties_overview') }}</h3>
        </v-col>

        <v-col class="col-12">
          <ContractCreatedPartiesPreview />
        </v-col>
      </v-row>

      <v-row class="pt-3 pb-2 mx-auto" align="center" justify="center" no-gutters>
        <v-col class="col-12">
          <h3 class="complete__subtitle">{{ $t('contract.waiting.undone_documents') }}</h3>
        </v-col>

        <v-col class="col-12">
          <ContractCreatedUndoneContractsLinks />
        </v-col>
      </v-row>

      <v-divider class="my-6"></v-divider>

      <v-btn class="back__button" text @click="onRedirectToDashboard">
        <v-icon color="primary" left>mdi-arrow-left</v-icon>
        {{ $t('contract.back_to_dashboard') }}
      </v-btn>
    </v-container>
  </v-container>
</template>

<script>
import { defineComponent } from 'vue'
import ContractCreatedPartiesPreview from '@/features/contracts/components/ContractCreatedPartiesPreview.vue'
import ContractCreatedUndoneContractsLinks from '@/features/contracts/components/ContractCreatedUndoneContractsLinks.vue'

export default defineComponent({
  name: 'ContractCollectionCreated',
  components: {
    ContractCreatedPartiesPreview,
    ContractCreatedUndoneContractsLinks,
  },
  computed: {
    headerImageFile() {
      return require('@/features/contracts/assets/undraw_subscriber_re_om92.svg')
    },
  },
  methods: {
    onRedirectToContractDetail() {
      return this.$router.push({
        name: 'documentsDetail',
        params: {
          contract_id: this.$route.params.contractId,
          workspace_id: this.$route.params.workspaceId,
        },
      })
    },
    onRedirectToDashboard() {
      return this.$router.push({
        name: 'dashboard',
        params: {
          workspace_id: this.$route.params?.workspaceId,
        },
      })
    },
  },
})
</script>

<style lang="scss" scoped>
.complete {
  &__wrapper {
    max-height: 981px;
    max-width: 920px;
    border-radius: 5px;
    background-color: #ffffff;
  }

  &__wrapper-content {
    max-width: 540px;
    width: 100%;
  }

  &__title {
    color: #424242;
    font-size: 30px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 37px;
  }

  &__subtitle {
    color: #424242;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 22.5px;
  }

  &__info {
    color: #495057;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 20px;
  }
}

.back__button {
  color: #424242;
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 0;
  line-height: 20px;
}
</style>
